<template>
  <AcaoItem titulo="Upload de Notas do Enem" :color="color" :historico="historico.historicosUploadNotasEnem">
    <template v-slot:default>
      <p>
        Por favor, escolha o arquivo para fazer o upload.
        <br/>
        O arquivo deverá ser adquirido no site do MEC.
      </p>
      <v-file-input
          :disabled="!processo.podeFazerAcoes"
          label="Selecione o arquivo..."
          v-model="arquivo"
          accept=".txt, .csv, .xlsx, .xls"
      />
    </template>
    <template v-slot:botao>
      <v-btn
          class="mt-1"
          :loading="loading"
          :disabled="!arquivo || !processo.podeFazerAcoes || notas.length === 0"
          color="primary"
          outlined
          @click="preview=true"
      >
        Preview
      </v-btn>
      <v-btn
          class="mt-1 ml-2"
          color="accent"
          :disabled="!historico.historicosUploadNotasEnem"
          outlined
          @click="visualizarUploadNotasEnem"
      >
        Visualizar
      </v-btn>
    </template>
    <template #extra>
      <v-dialog v-model="preview">
        <v-card>
          <v-card-title
              class="primary white--text"
          >
            Preview Upload Notas
          </v-card-title>
          <v-card-text>
            <v-data-table :headers="headers" :items="notas"
                          class="elevation-1 mt-5"
            >

            </v-data-table>
          </v-card-text>
          <v-card-actions
          class="justify-end"
          >
            <v-btn
                class="mt-1"
                :loading="loading"
                :disabled="!arquivo || !processo.podeFazerAcoes"
                color="error"
                outlined
                @click="preview=false"
            >
              Cancelar
            </v-btn>
            <v-btn
                class="mt-1"
                :loading="loading"
                :disabled="!arquivo || !processo.podeFazerAcoes"
                color="primary"
                outlined
                @click="enviarArquivo(); preview=false"
            >
              Enviar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </AcaoItem>
</template>

<script>
import uploadNotaEnemService from "@/services/uploadNotaEnem.service";
import {mapActions} from "vuex";
import AcaoItem from "./AcaoItem";
import XLSX from "xlsx";

export default {
  name: "UploadNotasEnemItem",
  components: {AcaoItem},
  props: {
    color: {type: String, required: true},
    processo: {type: Object, required: true},
    historico: {type: Object, required: true}
  },

  data() {
    return {
      arquivo: null,
      loading: false,
      notas: [],
      preview: false,
      headers: [
        {text: "Numero Enem", value: "numeroEnem"},
        {text: "CPF", value: "cpf"},
        {text: "Nome", value: "nome"},
        {text: "Ciências da Natureza", value: "notaCienciasNatureza"},
        {text: "Ciências Humanas", value: "notaCienciasHumanas"},
        {text: "Linguagens", value: "notaLinguagens"},
        {text: "Matemática", value: "notaMatematica"},
        {text: "Redação", value: "notaRedacao"}
      ]
    };
  },

  methods: {
    ...mapActions("avisos", ["exibirAviso"]),

    async enviarArquivo() {
      let loader = this.$loading.show();
      this.loading = true;

      await uploadNotaEnemService
          .uploadNotasEnem(this.notas, this.processo.oid)
          .then(() => {
            this.$emit("feito");
            this.exibirAviso({
              mensagem: "Upload realizado com sucesso",
              tipo: "success"
            });
          })
          .catch(error => {
            let mensagem = "Não foi possível realizar o upload";
            if (error.response.status === 409) {
              mensagem = error.response.data.msg;
            }
            this.exibirAviso({
              mensagem: mensagem,
              tipo: "error"
            });
          });

      this.arquivo = null;

      this.loading = false;
      loader.hide();
    },

    visualizarUploadNotasEnem() {
      this.$router.push({
        name: "NotasEnemFiltro",
        params: {processoOid: this.processo.oid}
      });
    },

    headerPreview(){
      return Array.from(this.headers.map(h => {
        return h.value;
      }));
    },

    previewFile(file) {
      let headers = this.headerPreview();
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.onload = (file) => {

          let data = file.target.result;

          let workbook = XLSX.read(data, {type: 'binary'});

          let sheetName = workbook.SheetNames[0];

          let worksheet = workbook.Sheets[sheetName];

          resolve(XLSX.utils.sheet_to_json(worksheet, {header: headers, blankrows: true, raw: false}));
        };
        reader.readAsBinaryString(file);
      })

    }
  },

  watch: {
    arquivo: {
      async handler() {
        if (this.arquivo != null) {
          this.notas = await this.previewFile(this.arquivo);
          this.preview = true;
        } else{
          this.notas = [];
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped>

</style>
