import Vue from "vue";

export default {
  uploadNotasEnem(notas, processoOid) {
    return Vue.axios.post("uploadNotaEnem/" + processoOid, notas);
  },

  recuperarFiltro(paramsFiltro, pagina, numElementosPagina, ordenacao) {
    return Vue.axios.post("uploadNotaEnem/filtro", {
      params: {
        processoOid: paramsFiltro.processoOid,
        opcaoOid: paramsFiltro.opcaoOid,
        nome: paramsFiltro.nome,
        cpf: paramsFiltro.cpf,
        situacoesInscricaoOid: paramsFiltro.situacoesInscricaoOid,
        existeNota: paramsFiltro.existeNota
      },
      pagina,
      numElementosPagina,
      ordenacao
    })
  }
};
